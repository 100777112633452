import Vue from 'vue'
import App from './App.vue'

Vue.config.productionTip = false
Vue.prototype.$TRIAL_DAYS = 14;

Vue.prototype.$STRIPE_PK = 'pk_live_51N2adtANRLRb2OclbphcXmypySTaQjwPZTJF1k4HxYN4on8n3GVvd4GZWDyyQmKBJLEgh5FKVLpI7t3SCbwGsBXb00uVQty7mv';

if(window.location.hostname != 'www.tourial.it' && window.location.hostname != 'tourial.it'){
  Vue.prototype.$STRIPE_PK = 'pk_test_51N2adtANRLRb2OcljbTnbwXPmwXdLJn2hCex3YqEsECrDGmWZiWBgxhRe6iA79wEecmREtcfuchveTUU6jVbcAM300OH8TVBSC';
}


import Vuesax from 'vuesax'

import 'vuesax/dist/vuesax.css' //Vuesax styles
Vue.use(Vuesax, {
  // options here
  colors: {
    primary:'#EF7E18',
    dark:'rgba(0, 0, 0, 0.1)'
  }
})

import VueSlideoutPanel from 'vue2-slideout-panel';
Vue.use(VueSlideoutPanel);


import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';

Vue.component('v-select', vSelect)

import VueRouter from 'vue-router'
Vue.use(VueRouter)

import 'boxicons/css/boxicons.min.css'
const vRouter = new VueRouter({
  mode: 'history',
  routes: require('./routes.js').routes
})
// global vue variable for navigation history
Vue.prototype.$NAVIGATION_HISTORY = new Array();
const DEFAULT_TITLE = 'Tourial';

function hasQueryParams(route) {
  return !!Object.keys(route.query).length
}

vRouter.beforeEach((to, from, next) => {
   // if from name equals 'Viaggio' and to name equals 'Scheda del luogo', override to meta params 'menu' with 'Viaggi', otherwise set it to 'Esplora'
  if(to.name == 'Scheda del luogo'){
    if(from.name == 'Viaggio'){
      to.meta.menu = 'Viaggi';
    } else {
      to.meta.menu = 'Esplora';
    }
  }


   if(!hasQueryParams(to) && hasQueryParams(from)){
    if(from.query.status == 'null'){
      delete from.query.status;
    }
    next({ ...to, query: from.query });
  } else {
    next()
  }
})

vRouter.afterEach((to, from) => {
  Vue.nextTick(() => {
      document.title = to.meta.title != undefined ? (DEFAULT_TITLE + ' | ' + to.meta.title) : DEFAULT_TITLE;
  });
  // detect navigation back
  if (Vue.prototype.$NAVIGATION_HISTORY.length > 0 && Vue.prototype.$NAVIGATION_HISTORY[Vue.prototype.$NAVIGATION_HISTORY.length - 1].fullPath == to.fullPath) {
    Vue.prototype.$NAVIGATION_HISTORY.pop();
  } else {
    Vue.prototype.$NAVIGATION_HISTORY.push(from);
  }

  if(to.meta.menu != from.meta.menu && from.meta.menu != 'HomePage') { // except for homepage we reset it
    // reset navigation history
    Vue.prototype.$NAVIGATION_HISTORY = new Array();
  }
});




import "@fontsource/montserrat"; // Defaults to weight 400.
import "@fontsource/montserrat/500.css"; // Weight 500.
import "@fontsource/montserrat/600.css"; // Weight 500.
import "@fontsource/montserrat/700.css"; // Weight 500.
import "@fontsource/montserrat/800.css"; // Weight 500.


import GAuth from 'vue-google-oauth2'
const gauthOption = {
  clientId: '34325553753-dk8esvk9sda8bd2ev3m1a4721adccldl.apps.googleusercontent.com',
  scope: 'profile email',
  prompt: 'select_account'
}
Vue.use(GAuth, gauthOption)

import VueI18n  from 'vue-i18n'
Vue.use(VueI18n)

const i18n = new VueI18n({
  locale: 'it',
  fallbackLocale: 'en',
  messages: {
    it: require('./locales/it.json'),
    en: require('./locales/en.json'),
    es: require('./locales/es.json'),
    fr: require('./locales/fr.json'),
    zh: require('./locales/zh.json'),
    de: require('./locales/de.json'),
    pt: require('./locales/pt.json')
  }
})

const VueCookie = require('vue-cookie');
Vue.use(VueCookie);


new Vue({
  render: h => h(App),
  router: vRouter,
  i18n
}).$mount('#app')

export const router = vRouter
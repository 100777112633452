export const routes = [
    

    {
      name: 'HomePage',
      path: '/',
      component: () => import('./pages/HomePage.vue'),
      meta: {
        hiddenMenu: true,
        map: false,
        breadcumb: false,  // mobile
        menu: 'HomePage'
      },
    },

    { 
      name: 'Export Trip',
      path: '/export-trip/:tripId',
      component: () => import('./components/elements/Trip.vue'),
      meta: {
        print: true,
        map: false,
        breadcumb: false,  // mobile
        menu: 'Explore',
        sitemap: { ignoreRoute: true }
      },
    },

    { 
      name: 'Legal - Tos',
      path: '/tos',
      component: () => import('./components/elements/TOS.vue'),
      meta: {
        title: 'TOS',
        map: false,
        breadcumb: true,  // mobile
        menu: 'Explore',
        fullPage: true
      },
    },

    { 
      name: 'Legal - Cookie Policy',
      path: '/cookie-policy',
      component: () => import('./components/elements/CookiePolicy.vue'),
      meta: {
        title: 'Cookie Policy',
        map: false,
        breadcumb: true,  // mobile
        menu: 'Explore',
        fullPage: true
      },
    },

    { 
      name: 'Legal - Privacy Policy',
      path: '/privacy-policy',
      component: () => import('./components/elements/PrivacyPolicy.vue'),
      meta: {
        title: 'Privacy Policy',
        map: false,
        breadcumb: true,  // mobile
        menu: 'Explore',
        fullPage: true
      },
    },

    /*
    { 
      name: 'AboutUs',
      path: '/about-us',
      component: () => import('./pages/AboutUs.vue'),
      meta: {
        title: 'About Us',
        map: false,
        breadcumb: true,  // mobile
        menu: 'AboutUs'
      },
    },
    */


    { 
      name: 'ContactUs',
      path: '/contact-us',
      component: () => import('./pages/ContactUs.vue'),
      meta: {
        title: 'Contact Us',
        map: false,
        breadcumb: true,  // mobile
        menu: 'ContactUs'
      },
    },

    { 
      name: 'Blog',
      path: '/blog',
      component: () => import('./pages/Blog.vue'),
      props: true,
      meta: {
        title: 'Blog',
        breadcumb: true,  // mobile
        menu: 'Blog',
      },
      children: [{
        name: 'Post',
        path: 'post/:articleId',
        components: {
          mobile: () => import('./components/elements/Post.vue'),
        },
        meta: {
          title: 'Blog',
          side: true,
          breadcumb: true, // mobile
          menu: 'Blog',
          sitemap: { ignoreRoute: true }
        }
      },]
    },

    { 
      name: 'ADM_POI',
      path: '/administration/pois',
      component: () => import('./pages/Administration/POIs.vue'),
      props: true,
      meta: {
        title: 'Admin',
        breadcumb: true,  // mobile
        menu: 'ADM_POI',
        auth: true,
        admin: true,
        sitemap: { ignoreRoute: true }
      }
    },

    { 
      name: 'ADM_Partner',
      path: '/administration/partner',
      component: () => import('./pages/Administration/Partner.vue'),
      props: true,
      meta: {
        title: 'Admin',
        breadcumb: true,  // mobile
        menu: 'ADM_Partner',
        auth: true,
        admin: true,
        sitemap: { ignoreRoute: true }
      }
    },

    { 
      name: 'ADM_Link',
      path: '/administration/links',
      component: () => import('./pages/Administration/Links.vue'),
      props: true,
      meta: {
        title: 'Admin',
        breadcumb: true,  // mobile
        menu: 'ADM_Link',
        auth: true,
        admin: true,
        sitemap: { ignoreRoute: true }
      }
    },

    { 
      name: 'ADM_Analytics',
      path: '/administration/analytics',
      component: () => import('./pages/Administration/Analytics.vue'),
      props: true,
      meta: {
        title: 'Admin',
        breadcumb: true,  // mobile
        menu: 'ADM_Analytics',
        auth: true,
        admin: true,
        sitemap: { ignoreRoute: true }
      }
    },

    { 
      name: 'ADM_Targets',
      path: '/administration/targets',
      component: () => import('./pages/Administration/Targets.vue'),
      props: true,
      meta: {
        title: 'Admin',
        breadcumb: true,  // mobile
        menu: 'ADM_Targets',
        auth: true,
        admin: true,
        sitemap: { ignoreRoute: true }
      }
    },

    { 
      name: 'ADM_Notifications',
      path: '/administration/notifications',
      component: () => import('./pages/Administration/Notifications.vue'),
      props: true,
      meta: {
        title: 'Admin',
        breadcumb: true,  // mobile
        menu: 'ADM_Notifications',
        auth: true,
        admin: true,
        sitemap: { ignoreRoute: true }
      }
    },

    { 
      name: 'ADM_Coupons',
      path: '/administration/coupons',
      component: () => import('./pages/Administration/Coupons.vue'),
      props: true,
      meta: {
        title: 'Admin',
        breadcumb: true,  // mobile
        menu: 'ADM_Coupons',
        auth: true,
        admin: true,
        sitemap: { ignoreRoute: true }
      }
    },

    { 
      name: 'Esplora',
      path: '/explore',
      component: () => import('./pages/Explore.vue'),
      props: true,
      meta: {
        title: 'Explore',
        map: true,
        breadcumb: false,  // mobile
        menu: 'Esplora',
        auth: true
      },
      children: [
        {
          name: 'Benvenuto',
          path: 'welcome',
          components: {
            mobile: () => import('./components/elements/Welcome.vue'),
            desktopCenter: () => import('./components/elements/Welcome.vue'),
          },
          meta: {
            breadcumb: false,  // mobile
            menu: 'Esplora',
            preauth: true
          },
        },

        {
          name: 'Ospite',
          path: 'guest',
          props: true,
          components: {
            mobile: () => import('./components/elements/Guest.vue'),
            desktopCenter: () => import('./components/elements/Guest.vue'),
          },
          meta: {
            title: 'Guest',
            breadcumb: false,  // mobile
            menu: 'Esplora',
            preauth: true
          },
        },


        {
          name: 'Accedi',
          path: 'login',
          props: true,
          components: {
            mobile: () => import('./components/elements/Login.vue'),
            desktopCenter: () => import('./components/elements/Login.vue'),
          },
          meta: {
            title: 'Login',
            breadcumb: false,  // mobile
            menu: 'Esplora',
            preauth: true
          },
        },

        {
          name: 'Dimenticata',
          path: 'forgot',
          props: true,
          components: {
            mobile: () => import('./components/elements/Forgot.vue'),
            desktopCenter: () => import('./components/elements/Forgot.vue'),
          },
          meta: {
            title: 'Forgot',
            breadcumb: false,  // mobile
            menu: 'Esplora',
            preauth: true
          },
        },

        {
          name: 'Ripristina',
          path: 'recover/:email/:token',
          props: true,
          components: {
            mobile: () => import('./components/elements/Recover.vue'),
            desktopCenter: () => import('./components/elements/Recover.vue'),
          },
          meta: {
            title: 'Recover',
            breadcumb: false,  // mobile
            menu: 'Esplora',
            preauth: true,
            sitemap: { ignoreRoute: true }
          },
        },

        {
          name: 'Registrati',
          path: 'register',
          components: {
            mobile: () => import('./components/elements/Register.vue'),
            desktopCenter: () => import('./components/elements/Register.vue'),
          },
          meta: {
            title: 'Register',
            breadcumb: false,  // mobile
            menu: 'Esplora',
            preauth: true
          },
        },

        {
          name: 'Scheda del luogo',
          path: 'poi/:id',
          components: {
            mobile: () => import('./components/elements/POI.vue'),
          },
          meta: {
            title: 'Place',
            side: true,
            map: true, // should open street map be rendered?
            breadcumb: true, // mobile
            menu: 'Esplora',
            auth: true,
            sitemap: { ignoreRoute: true }
          }
        },

        {
          name: 'Recensione',
          path: 'poi/:id/reviews/new/:reviewId?',
          components: {
            mobile: () => import('./components/elements/NewReview.vue'),
          },
          meta: {
            title: 'Review',
            side: true,
            map: true, // should open street map be rendered?
            breadcumb: true, // mobile
            menu: 'Esplora',
            auth: true,
            sitemap: { ignoreRoute: true }
          }
        },

        {
          name: 'Recensioni',
          path: 'poi/:id/reviews',
          props: true,
          components: {
            mobile: () => import('./components/elements/Reviews.vue'),
          },
          meta: {
            title: 'Reviews',
            side: true,
            map: true, // should open street map be rendered?
            breadcumb: true, // mobile
            menu: 'Esplora',
            auth: true,
            sitemap: { ignoreRoute: true }
          }
        },

        {
          name: 'Viaggi',
          path: 'trips',
          components: {
            mobile: () => import('./components/elements/Trips.vue'),
          },
          meta: {
            title: 'Trips',
            side: true,
            map: true, // should open street map be rendered?
            breadcumb: true, // mobile
            menu: 'Viaggi',
            auth: true,
            sitemap: { ignoreRoute: true }

          }
        },

        {
          name: 'Profilo',
          path: 'profile',
          components: {
            mobile: () => import('./components/elements/Profile.vue'),
          },
          meta: {
            title: 'Profile',
            side: true,
            map: true, // should open street map be rendered?
            breadcumb: true, // mobile
            menu: 'Profilo',
            auth: true,
            sitemap: { ignoreRoute: true }

          }
        },

        {
          name: 'Modifica profilo',
          path: 'profile/edit',
          components: {
            mobile: () => import('./components/elements/EditProfile.vue'),
          },
          meta: {
            title: 'Profile',
            side: true,
            map: true, // should open street map be rendered?
            breadcumb: true, // mobile
            menu: 'Profilo',
            auth: true,
            sitemap: { ignoreRoute: true }

          }
        },

        {
          name: 'Notifiche',
          path: 'profile/notifications',
          components: {
            mobile: () => import('./components/elements/Notifications.vue'),
          },
          meta: {
            title: 'Notifications',
            side: true,
            map: true, // should open street map be rendered?
            breadcumb: true, // mobile
            menu: 'Profilo',
            auth: true,
            sitemap: { ignoreRoute: true }

          }
        },

        {
          name: 'TOS',
          path: 'profile/tos',
          components: {
            mobile: () => import('./components/elements/TOS.vue'),
          },
          meta: {
            title: 'TOS',
            side: true,
            map: true, // should open street map be rendered?
            breadcumb: true, // mobile
            menu: 'Profilo'

          }
        },

        {
          name: 'Privacy Policy',
          path: 'profile/privacy-policy',
          components: {
            mobile: () => import('./components/elements/PrivacyPolicy.vue'),
          },
          meta: {
            title: 'Privacy Policy',
            side: true,
            map: true, // should open street map be rendered?
            breadcumb: true, // mobile
            menu: 'Profilo'

          }
        },

        {
          name: 'Sottoscrizione',
          path: 'profile/subscription',
          components: {
            mobile: () => import('./components/elements/Subscription.vue'),
          },
          meta: {
            title: 'Subscription',
            side: true,
            map: true, // should open street map be rendered?
            breadcumb: true, // mobile
            menu: 'Profilo',
            auth: true,
            sitemap: { ignoreRoute: true }

          }
        },

        {
          name: 'Fatturazione',
          path: 'profile/subscription/invoicing',
          components: {
            mobile: () => import('./components/elements/Invoicing.vue'),
          },
          props: true, // handle coupon if passed
          meta: {
            title: 'Invoicing',
            side: true,
            map: true, // should open street map be rendered?
            breadcumb: true, // mobile
            menu: 'Profilo',
            auth: true,
            sitemap: { ignoreRoute: true }

          }
        },

        {
          name: 'Cancellazione',
          path: 'profile/subscription/cancelled',
          components: {
            mobile: () => import('./components/elements/SubscriptionCancelled.vue'),
          },
          meta: {
            side: true,
            map: true, // should open street map be rendered?
            breadcumb: true, // mobile
            menu: 'Profilo',
            auth: true,
            sitemap: { ignoreRoute: true }

          }
        },

        {
          name: 'Attivata',
          path: 'profile/subscription/confirmed',
          components: {
            mobile: () => import('./components/elements/SubscriptionConfirmed.vue'),
          },
          meta: {
            side: true,
            map: true, // should open street map be rendered?
            breadcumb: true, // mobile
            menu: 'Profilo',
            auth: true,
            sitemap: { ignoreRoute: true }

          }
        },

        {
          name: 'Viaggio',
          path: 'trips/:tripId',
          components: {
            mobile: () => import('./components/elements/Trip.vue'),
          },
          meta: {
            title: 'Trip',
            side: true,
            map: true, // should open street map be rendered?
            breadcumb: true, // mobile
            menu: 'Viaggi',
            auth: true,
            sitemap: { ignoreRoute: true },
          }
        },

        {
          name: 'Preferiti',
          path: 'favourites',
          components: {
            mobile: () => import('./components/elements/Favourites.vue'),
          },
          meta: {
            title: 'Favourites',
            side: true,
            map: true, // should open street map be rendered?
            breadcumb: true, // mobile
            menu: 'Preferiti',
            auth: true,
            sitemap: { ignoreRoute: true }

          }
        },
      ]
    }
  ];